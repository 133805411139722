import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import './assets/css/common.scss';
import 'vant/lib/index.css';
import store from './store';
import router from './router';
import { message } from '@/assets/js/message.js';
import axios from '@/assets/js/axios.js';
import './assets/iconfont/iconfont.css';

import {
  Tabbar, TabbarItem, Checkbox, Toast, Icon, NoticeBar, Popup, Picker,
  Uploader, Collapse, CollapseItem, List, Empty, Pagination, Loading,
  RadioGroup, Radio, DropdownMenu, DropdownItem
} from 'vant';
Vue.use(Checkbox);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Toast);
Vue.use(Icon);
Vue.use(NoticeBar);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Uploader);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(List);
Vue.use(Empty);
Vue.use(Pagination);
Vue.use(Loading);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);

// 全局loading
let loading = null;
const showLoading = function () {
  const lang = store.state.lang;
  loading = Toast.loading({
    message: lang === 'cn' ? '加载中...' : lang === 'hi' ? 'लोड किया जा रहा है' : 'loading...',
    forbidClick: true,
    loadingType: 'spinner',
  });
}
const hideLoading = function () {
  loading && loading.clear();
}

const url = 'wss://server.mqgstock.com/wss:2333';
let socket = null;
let pingTimer = null;
const webSocketInit = function (type = 'normal') {
  socket = new WebSocket(url);
  pingTimer && clearInterval(pingTimer); //重连时清除上一次的ping

  // 连接建立时触发的事件处理程序
  socket.onopen = function() {
    window.isConnected = true;
    if (type === 'reload') {
      // 重连成功后刷新当前页面
      window.location.reload();
    }
    // 发送消息到服务器
    const params = { type: 'ping' };
    socket.send(JSON.stringify(params));
    pingTimer = setInterval(() => {
      socket.send(JSON.stringify(params));
    }, 10000)
  };

  // 连接关闭时触发的事件处理程序
  socket.onclose = function() {
    window.isConnected = false;
    // 断线重连
    const timer = setTimeout(() => {
      clearTimeout(timer);
      webSocketInit('reload');
    }, 5000)
  };

  // 发生错误时触发的事件处理程序
  socket.onerror = function() {
    window.isConnected = false;
  };
}
webSocketInit();

Vue.config.productionTip = false;
Vue.prototype.$message = message;
Vue.prototype.$axios = axios;
Vue.prototype.$showLoading = showLoading;
Vue.prototype.$hideLoading = hideLoading;
Vue.prototype.$socket = socket;

new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
